.dashboard-table-title {
  font-weight: 400;
  margin-top: 1rem;
  margin-top: 2rem;
}
.card-container {
  margin: 20px;
}

.card {
  text-align: center;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
  cursor: pointer;
}

.counter {
  font-size: 24px;
  color: #1677ff;
}

.static-text {
  text-align: center;
  font-size: 18px;
  color: #000;
}
