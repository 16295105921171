.query-result-title {
  font-weight: 400;
  margin-top: 1rem;
}
.query-label-container {
 
}
.query-filter-modal {
  .ant-modal-content {
    max-height: 80vh;
    overflow-y: auto;
    width: 700px;
  }
}
