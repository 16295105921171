// @import '~antd/dist/antd.css';
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&family=Inter&display=swap");

html {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  text-size-adjust: 100%;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  // overflow: visible;
  overflow: auto;
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
body {
  font-family: "Inter", sans-serif !important;
}
.app-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  .header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 1rem;
    .logo-container {
      color: #fff;
      font-size: 24px;
      margin: 0;
      span {
        margin-right: 1rem;
      }
    }
    .notification-icon-container {
      color: #fff;
      .anticon-bell {
        color: #fff;
        font-size: 22px;
      }
    }
  }
  .ant-layout {
    background: none;
  }
  .left-menu-container {
    width: 100px;
    height: 100%;
    background: #fff;
    // padding: 1rem;
    padding: 2rem 0rem;
    height: calc(100vh - 64px);
    background-color: #fbfbfb;
    .left-menu {
      background: none;
      border: none;
      .ant-menu-sub {
        background: none;
        margin-bottom: 2rem;
        .ant-menu-item {
          margin-right: 0;
          width: 100%;
          border-radius: 0;
        }
      }
    }
  }
  .page-container {
    display: flex;
    flex-direction: column;
    width: calc(100% - 100px);
    height: calc(100vh - 64px);
    padding: 1rem 2.5rem;
    overflow: auto;
    background: #fff;

    .title {
      margin: 0;
      font-weight: 600;
      font-size: 24px;
      line-height: 1.26667;
      letter-spacing: -0.00833em;
      margin-bottom: 2rem;
    }
    .search-box-container {
      // .ant-col{
      //     padding: 0 16px;
      // }
      margin-bottom: 2rem;
    }
    .search-component {
      width: 100%;
    }
    .search-label {
      margin-bottom: 0.5rem;
      font-size: 14px;
      font-weight: 500;
    }
    .chart-first-row {
      margin-bottom: 2rem;
    }
    .chart-container {
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03),
        0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
      border: 1px solid #f4f4f4;

      padding: 1rem;
    }
  }
}
.chat-widget-container {
  width: 40vw; /* Adjust width as needed */
  max-height: 83vh; /* Adjust height as needed */
  height: 83vh;
  display: flex;
  flex-direction: column;
  .message-enter {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }

  .message-enter-active {
    opacity: 1;
    transform: translateY(0);
  }

  .message-exit {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }

  .message-exit-active {
    opacity: 0;
    transform: translateY(20px);
  }
  .ant-list-item-meta-description {
    color: #000 !important;
  }

  .user {
    display: flex;
    justify-content: flex-end;
    border: none;
    margin-bottom: 1rem;
    .ant-list-item-meta {
      width: 50%;
      max-width: 50%;
      background: aliceblue;
      padding: 1rem;
      border-radius: 20px;
      display: flex;
      .ant-list-item-meta-title {
        margin: 0;
      }
      .ant-list-item-meta-content {
        margin-left: 0.5rem;
      }
    }
  }
  .bot {
    display: flex;
    justify-content: left;
    border: none;
    margin-bottom: 1rem;
    .ant-list-item-meta {
      width: 50%;
      max-width: 50%;
      background: #fff0f0;
      padding: 1rem;
      border-radius: 20px;
      display: flex;
      .ant-list-item-meta-title {
        margin: 0;
      }
      .ant-list-item-meta-content {
        margin-left: 0.5rem;
      }
    }
  }
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  display: flex;
  flex-direction: column-reverse; /* Display messages in reverse order */
}

.message {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.message-new {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.message.bot {
  justify-content: flex-start; /* Bot messages on the left */
}

.chat-input {
  display: flex;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #ccc;
}

.chat-icon {
  position: fixed;
  bottom: 20px;
  right: 10px;
  font-size: 32px;
  color: #2995f3;
}
.chat-input {
  display: flex;
  justify-content: space-between;
  button {
    margin-left: 2rem;
  }
}
.toolbar {
  border-radius: 4px;
  .tool-1 {
    margin-right: -18px;
  }
  .para {
    font-size: 10px;
    font-family: "Inter";
    margin: 0;
  }
  .num {
    font-size: larger;
  }

  .dotted {
    text-align: end;
    margin-bottom: 0;
    margin-right: 1rem;
    margin-top: 0;
  }
  .leg1 {
    font-size: 10px;
    font-family: "Inter";
    // margin-top: -8px;
    margin: 0;
  }
  .tool-2 {
    margin-right: -28px;
  }
  .leg2 {
    font-size: 10px;
    font-family: "Inter";
    margin-top: 35px;
    margin-right: 14px;
  }
}
.graph-dropdown {
  margin-top: 1rem;
}
.add-dropdown-row {
  margin-bottom: 1rem;
}
.rules-text-box {
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  padding: 2rem;
  min-height: 300px;
  white-space: initial;
}
// .code-snippet{
//   overflow-wrap: break-word;
//   white-space: pre-wrap;
//   text-align: left;
//   background: #d9d9d9;
//   padding: 0.5rem;
//   justify-content: flex-start;
//   display: flex;
//   font-size: 0.87em;
//   white-space: initial;
// }
.github-commit-background {
  .ant-drawer-body {
    background: #000 !important;
  }
}
.permission-code-container {
  h4 {
    color: #fff;
  }
  .github-code-editor {
    background-color: #1e1e1e;
    color: #f0f0f0;
    padding: 15px;
    border-radius: 4px;
    font-family: monospace;
    overflow: auto;
  }

  .github-code-editor .token.comment,
  .github-code-editor .token.prolog,
  .github-code-editor .token.doctype,
  .github-code-editor .token.cdata {
    color: slategray;
  }

  .github-code-editor .token.punctuation {
    color: #ccc;
  }

  .github-code-editor .token.namespace {
    opacity: 0.7;
  }

  .github-code-editor .token.property,
  .github-code-editor .token.tag,
  .github-code-editor .token.constant,
  .github-code-editor .token.symbol,
  .github-code-editor .token.deleted {
    color: #f92672;
  }

  .github-code-editor .token.boolean,
  .github-code-editor .token.number {
    color: #ae81ff;
  }

  .github-code-editor .token.selector,
  .github-code-editor .token.attr-name,
  .github-code-editor .token.char,
  .github-code-editor .token.builtin,
  .github-code-editor .token.inserted {
    color: #a6e22e;
  }

  .github-code-editor .token.operator,
  .github-code-editor .token.entity,
  .github-code-editor .token.url,
  .github-code-editor .language-css .token.string,
  .github-code-editor .style .token.string {
    color: #f8f8f2;
    background: #272822;
  }

  .github-code-editor .token.atrule,
  .github-code-editor .token.attr-value,
  .github-code-editor .token.keyword {
    color: #f92672;
  }

  .github-code-editor .token.function,
  .github-code-editor .token.class-name {
    color: #a6e22e;
  }

  .github-code-editor .token.regex,
  .github-code-editor .token.important,
  .github-code-editor .token.variable {
    color: #e90; /* Color for keys (variables) */
  }

  .github-code-editor .token.string,
  .github-code-editor .token.attr-value.string {
    color: #a6e22e; /* Color for values */
  }

  .github-code-editor .token.important,
  .github-code-editor .token.bold {
    font-weight: bold;
  }

  .github-code-editor .token.italic {
    font-style: italic;
  }

  .github-code-editor .token.entity {
    cursor: help;
  }
}
.add-rule-version {
  padding-left: 2rem;
  background: #000;
  color: #fff;
  position: absolute;
  top: 64px;
  right: 0;
  width: 600px;
  padding-right: 2rem;
  max-height: calc(100vh - 64px);
  overflow: auto;
}
.userTable-container {
  .ant-table-expanded-row {
    background: beige;
  }
}
