.github-commit-card {
    background-color: #fff;
    border: 1px solid #d1d5da;
    border-radius: 6px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 16px;
    padding: 16px;
    border: 1px solid var(--color-border-default, #ddd);
  }

  
  .commit-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }
  

  .commit-date {
    font-size: 12px;
    font-weight: bold;
    color: #0366d6;
    margin: 0;
  }
  
  .commit-time {
    font-size: 12px;
    color: #586069;
    margin: 0;
  }
  
  .commit-details {
    margin: 0;
  }
  
  .commit-message {
    font-size: 14px;
    font-weight: bold;
    margin: 0 0 6px 0;
  }
  
  .commit-user {
    font-size: 12px;
    color: #586069;
    margin: 0;
  }
  
  .commit-permission {
    font-size: 14px;
    font-weight: bold;
    margin: 0;
  }
  
  .code-allowed {
    color: #28a745;
  }
  
  .code-denied {
    color: #cb2431;
  }
  .github-commit-card {
    background-color: #24292e;
    border: 1px solid #30363d;
    border-radius: 6px;
    margin-bottom: 16px;
    padding: 16px;
  }
  
  .commit-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }
  
  .commit-info {
    font-size: 12px;
    color: #e6edf3;
    margin: 0;
  }
  
  .commit-message {
    font-size: 16px;
    font-weight: bold;
    margin: 0 0 6px 0;
    color: #0366d6;
  }
  
  .commit-user {
    font-size: 12px;
    color: #586069;
    margin: 0;
  }
  
  .commit-permission {
    font-size: 14px;
    font-weight: bold;
    margin: 0;
  }
  
  .commit-code {
    background-color: #2d3338;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #3c4448;
    overflow: auto;
    color: #abb2bf;
  }
  
  .code-keyword {
    color: #e06c75;
  }
  
  .code-value {
    color: #98c379;
  }
  
  .code-allowed {
    color: #2cbe4e;
  }
  
  .code-denied {
    color: #cb2431;
  }
  