// .code-container{
//     background: #d9d9d9;
//     margin-bottom: 1rem;
// }
// .code-snippet{
//     display: flex;
//     flex-direction: column;
//     overflow-wrap: break-word;
//     white-space: pre-wrap;
//     text-align: left;
//     background: #d9d9d9;
//     padding: 0.5rem;
//     justify-content: flex-start;
//     display: flex;
//     font-size: 0.87em;
//     white-space: initial;
// }
.version-history-title{
    font-weight: 400;
    margin-top: 1rem;
}
.rules-dropdown{
    width: 100%;
}
.rules-text-box{
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    padding: 2rem;
    min-height: 300px;
    white-space: initial;
}